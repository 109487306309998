@mixin modal-colors($bg-color, $color) {
    .modal-content{
        background-color: $bg-color;
        color: $color;
    }

    .modal-header .close{
        color: $color;
    }


}
